/** @jsx jsx */
import { jsx } from 'theme-ui'
// import React from 'react'
import ReactMarkdown from 'react-markdown'
import unwrapImages from 'remark-unwrap-images'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Box, Text, Grid } from 'theme-ui'
import { useSpring, animated, config } from 'react-spring'
import { IconContext } from 'react-icons'
import { AiOutlineArrowLeft, AiOutlineArrowRight, AiOutlineClockCircle } from 'react-icons/ai'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
momentDurationFormatSetup(moment);

const ProjectTemplate = ({ data, pageContext }) => {
  const datestart = moment(data.strapiProject.date);
  // const dateyear = moment(data.strapiProject.date).format('YYYY');
  const dateend = moment(data.strapiProject.enddate).add(1, 'days');
  const dateduration = dateend.diff(datestart, 'days');
  const datedur = moment.duration(dateduration, 'days').format("y [years], M [months], w [weeks], d [days]", {
    largest: 1
  });

  const delay = 125;
  const fade = useSpring({
    config: config.gentle,
    from: { opacity: 0.01, transform: 'translate3d(0,-25px,0)' },
    to: async (next) => {
      await next({ opacity: 1, transform: 'translate3d(0,0,0)', delay: delay })
    },
  });
  const fade2 = useSpring({
    config: config.gentle,
    from: { opacity: 0.01, transform: 'translate3d(-10px,0,0)' },
    to: async (next) => {
      await next({ opacity: 1, transform: 'translate3d(0,0px,0)', delay: delay * 2 })
    },
  });
  const fade3 = useSpring({
    config: config.gentle,
    from: { opacity: 0.01, transform: 'translate3d(-10px,0,0)', },
    to: async (next) => {
      await next({ opacity: 1, transform: 'translate3d(0,0,0)', delay: delay * 3 })
    },
  });
  const fade4 = useSpring({
    config: config.gentle,
    from: { opacity: 0.01, transform: 'translate3d(-10px,0,0)' },
    to: async (next) => {
      await next({ opacity: 1, transform: 'translate3d(0,0,0)', delay: delay * 4 })
    },
  });
  const fade5 = useSpring({
    config: config.gentle,
    from: { opacity: 0.01, transform: 'translate3d(-10px,0,0)' },
    to: async (next) => {
      await next({ opacity: 1, transform: 'translate3d(0,0,0)', delay: delay * 5 })
    },
  });
  const fade6 = useSpring({
    config: config.gentle,
    from: { opacity: 0.01, transform: 'translate3d(0,10px,0)' },
    to: async (next) => {
      await next({ opacity: 1, transform: 'translate3d(0,0,0)', delay: delay * 6 })
    },
  });
  const AnimatedImg = animated(Img)
  const AnimatedBox = animated(Box)
  const AnimatedText = animated(Text)

  return (
    <Layout>
      <SEO title={data.strapiProject.title} />

      <Grid gap={[1, 2, 3]} columns={['15% 80%', 'repeat(5,1fr)']}>
        <AnimatedImg style={fade} sx={{
          zIndex: '-1',
          gridColumnStart: '1',
          gridColumnEnd: '6',
          gridRowStart: '1',
          gridRowEnd: '1',
          borderRadius: 5,
          height: ['25vh', '100%'],
        }} fluid={data.strapiProject.cover.childImageSharp.fluid} />

        <Box sx={{
          textShadow: 'project.shadow',
          color: 'background',
          gridRowStart: '1',
          gridRowEnd: '1',
          gridColumnStart: ['1', '2'],
          gridColumnEnd: '4',
          alignSelf: 'end',
          pb: ['1', '3'],
          pl: ['3', '0']
        }}>
          <animated.h1
            style={fade2}
            sx={{
              fontWeight: ['sbold'],
              m: '0px',
              whiteSpace: 'nowrap',
            }}>
            {data.strapiProject.title}
          </animated.h1>
          <animated.h2
            style={fade3}
            sx={{
              lineHeight: '1.25', fontWeight: ['body'], m: '0px', py: ['2'],
              borderBottom: 'dotted 2px white'
            }}>
            {data.strapiProject.summary}
          </animated.h2>
          <IconContext.Provider value={{ size: '1.25em' }}>
            <animated.h4
              style={fade4}
              sx={{
                fontWeight: 'bold',
                float: 'left',
                textTransform: 'uppercase',
                m: '0px',
                py: '2',
                overflow: 'hidden'
              }}>
              {data.strapiProject.category}
              {data.strapiProject.enddate &&
                <AnimatedText style={fade5} sx={{ ml: '3', display: 'inline', }}><AiOutlineClockCircle sx={{ mr: '1', verticalAlign: 'text-top', ml: '1' }} />{datedur}</AnimatedText>}
            </animated.h4>
          </IconContext.Provider>
          {/* <animated.h4
            style={fade5}
            sx={{fontWeight: 'heading', textTransform: 'uppercase', m: '0px', py: ['0','2'], }}>
            {data.strapiProject.date &&
              <Text sx={{ display: 'inline' }}> / {dateyear}</Text>
            }
          </animated.h4> */}
        </Box>

      </Grid>
      <AnimatedBox
        mt={[3, 4]}
        style={fade6}
        className="projectMDX"
      >
        <Grid className='grid-container' gap={[1, 2, 3]} columns={['15% 80%', 'repeat(5,1fr)']}
          sx={{ display: ['block', 'grid'] }}
        >
            <ReactMarkdown
              source={data.strapiProject.description}
              plugins={[unwrapImages]}
              renderers={{
                image: ({ src, alt }) => {
                  const image = data.strapiProject.description_images.find(element => element.base === src ); 
                  return <Img fluid={image.childImageSharp.fluid} alt={alt} />
                }
              }}
            />
        </Grid>
      </AnimatedBox>

      {/* PREVIOUS/NEXT NAVIGATION */}
      <Grid px={['0', '2']} pb={'4'} pt={'0'} sx={{ lineHeight: '1.25', width: '100%', position: 'fixed', left: '0', bottom: '0' }} columns={['max-content auto max-content']}>
        <IconContext.Provider value={{ size: '4em' }}>
          {pageContext.prevtitle &&
            <Box sx={{ float: 'left', position: 'relative', gridColumnStart: '1', fontSize: ['0', '1'], }}>
              <Link sx={{
                display: 'grid',
                gridTemplateColumns: 'auto auto',
                gridTemplateRows: 'auto',
                color: 'gray',
                py: '3',
                pr: '3',
                borderRadius: '10px',
                backgroundColor: 'rgba(255,255,255,0)',
                opacity: '0.5',
                ':hover': {
                  color: 'primary',
                  transition: 'opacity color background-color 0.3s ease-in',
                  backgroundColor: 'rgba(255,255,255,0.75)',
                  opacity: '1',
                },
                ':hover>div': {
                  transition: 'opacity 0.3s ease-in',
                  opacity: '1',
                },
              }}
                to={`/projects/${pageContext.prev}`}>
                <AiOutlineArrowLeft sx={{ display: 'block', gridColumnStart: '1', gridRowStart: '1' }} />
                <Box sx={{ whiteSpace: 'nowrap', gridColumnStart: '2', gridRowStart: '1', opacity: '0', fontSize: '1', fontWeight: 'body' }}>Previous project:</Box>
                <Box sx={{ whiteSpace: 'nowrap', pt: '16px', gridColumnStart: '2', gridRowStart: '1', opacity: '0', fontSize: '2', fontWeight: 'heading' }}>{pageContext.prevtitle}</Box>
              </Link>
            </Box>
          }
          {pageContext.nexttitle &&
            <Box sx={{ float: 'right', position: 'relative', gridColumnStart: '3', textAlign: 'right', fontSize: ['0', '1'] }}>
              <Link sx={{
                display: 'grid',
                gridTemplateColumns: 'auto auto',
                gridTemplateRows: 'auto',
                color: 'gray',
                py: '3',
                pl: '3',
                borderRadius: '10px',
                opacity: '0.5',
                ':hover': {
                  color: 'primary',
                  transition: 'opacity color background-color 0.3s ease-in',
                  backgroundColor: 'rgba(255,255,255,0.75)',
                  opacity: '1',
                },
                ':hover>div': {
                  transition: 'opacity 0.3s ease-in',
                  opacity: '1',
                },
              }}
                to={`/projects/${pageContext.next}`}>
                <Box sx={{ whiteSpace: 'nowrap', gridColumnStart: '1', gridRowStart: '1', opacity: '0', fontSize: '1', fontWeight: 'body' }}>Next project:</Box>
                <Box sx={{ whiteSpace: 'nowrap', pt: '16px', gridColumnStart: '1', gridRowStart: '1', opacity: '0', fontSize: '2', fontWeight: 'heading' }}>{pageContext.nexttitle}</Box>
                <AiOutlineArrowRight sx={{ display: 'block', gridColumnStart: '2', gridRowStart: '1' }} />
              </Link>
            </Box>
          }
        </IconContext.Provider>
      </Grid>
      {/* PREVIOUS/NEXT NAVIGATION */}

    </Layout>
  )
}

export default ProjectTemplate

export const query = graphql`
  query ProjectTemplate($id: String!) {
    strapiProject(id: {eq: $id} ) {
      id
      slug
      title
      category
      description
      description_images {
        childImageSharp {
          original {
            src
          }
          fluid(maxWidth: 1200, quality:100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        base
      }
      date
      enddate
      summary

      cover {
        childImageSharp {
          fluid(maxWidth: 1200, maxHeight: 600 cropFocus: CENTER, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`

// childStrapiProjectContent {
//   childMdx {
//     body
//     id
//   }
// }